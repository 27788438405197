:root {
  --header-height: 100px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100vw;
  height: var(--header-height);
  color: white;
  box-sizing: border-box;
}

.app-logo {
  height: var(--header-height);
  display: flex;
  align-items: center;
  gap: 30px;
}

h1.app-header__title {
  color: white;
  font-size: 30px;
  margin: 0;
}

.app-header__icon-container {
  font-size: 36px;
  line-height: 1em;
  color: white;
}

.app-layout {
  top: var(--header-height);
  position: fixed;
  height: calc(100vh - var(--header-height));
  width: 100vw;
}

.side-menu {
  overflow: auto;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}

.main-layout {
  margin-left: 300;
  overflow: hidden;
  overflow-x: auto;
}

.main-content {
  padding: 24px 36px;
  margin: 0;
  height: fit-content;
  overflow-x: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bulk-upload__row{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
  gap: 20px;

}

.bulk-upload__row .ant-upload.ant-upload-drag{
  width: 50%;
}
.bulk-upload__row .ant-upload-list.ant-upload-list-picture{
  width: 50%;
}

.tag-list__dropdown > div > .rc-virtual-list > .rc-virtual-list-holder > div > .rc-virtual-list-holder-inner > .ant-select-item-option-selected {
  background-color: #438ff8;
  gap: 0.5rem;
}

.tag-list__dropdown > div > .rc-virtual-list > .rc-virtual-list-holder > div > .rc-virtual-list-holder-inner > .ant-select-item-option-selected > .ant-select-item-option-content {
  flex: initial;
  color: white;
}

.tag-list__dropdown > div > .rc-virtual-list > .rc-virtual-list-holder > div > .rc-virtual-list-holder-inner > .ant-select-item-option-selected > .ant-select-item-option-state {
  color: white;
}

.ant-select-disabled:where(.css-dev-only-do-not-override-1sn0ohr).ant-select-multiple .ant-select-selection-item {
    color: black;
    cursor: not-allowed;
}

.neutralForm {
  color: black !important;
  background: white !important;

  * {
    color: black !important;
    background: white !important;
  }

  svg, svg * {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
